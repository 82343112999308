import React ,{useState,useEffect} from 'react';
import { AuthProvider, AuthService } from 'react-oauth2-pkce'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {hentNetatmo} from "../../fire";
import Typography from '@material-ui/core/Typography';
import NetatmoLogin from './NetatmoLogin'
import NetatmoData from './NetatmoData'
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({

    text: {
        padding: theme.spacing(0),
       
        
      },
      paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        
      },

    
  }));





function Netatmo (props){
    const classes = useStyles();
    const {oppdater, data} = props;
    const [devices, setDevices] = useState(data?data.device_liste:null);
    const [myData, setData] = useState(data?data:{});

    
    useEffect(() => {
        console.log("henter");
        oppdater(myData);
    
      }, [myData, oppdater, setData])
    

    function hentStasjonsData(device, refresh_token){
        console.log("hentStasjonsData")
        console.log(device);
        var listeElement = [];
        var sData = myData;
        sData.refresh_token = refresh_token;
            
        device.forEach(element => {

            var outdoor_id="";

        element.modules.forEach(module=>{
            if(module.type==='NAModule1'){
                console.log("fant utemodul");
                outdoor_id=module._id
            }
            
            

        });
        listeElement.push({navn: element.station_name,
            indoor_id: element._id,
            outdoor_id: outdoor_id});
        
        });
        sData.device_liste = listeElement;
        setData(sData);
        setDevices(listeElement);
        oppdater(myData);

    }

    const indoorData = (e) =>{
        var sData = myData;
        console.log("indoorData ", e);
        sData.indoor_id = e;
        setData(sData);
        oppdater(myData);
        
    }
    const outDoorData = (e) =>{
        var sData = myData;
        console.log("outdoorData ", e);
        sData.outdoor_id = e;
        setData(sData);
        oppdater(myData);
        
    }
    const oppdaterModule = (e) =>{
        console.log("oppdaterModule:", e);
        var sData = myData;
        console.log("outdoorData ", e);
        devices.forEach(element => {
            
            if(element.indoor_id === e){
                console.log("indoor", element);
                sData.outdoor_id = element.outdoor_id;
                sData.indoor_id = element.indoor_id;
            }

        })
        ;
        setData(sData);
        console.log("myData", myData);
        oppdater(myData);
        
    }

  
    const login = devices?<NetatmoData liste={devices} data={myData} oppdaterModule={oppdaterModule} outdoor={outDoorData}/>: <NetatmoLogin device={hentStasjonsData}  />;


return(
    <Grid item xs={12}   >
        <Paper className={classes.paper}>
        <Typography variant="h6"  color="inherit"  >
            Netatmo 
        </Typography>
       {login}
       </Paper>
    </Grid>
   

)
}

export default Netatmo;