import { red, deepOrange, blueGrey } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    text: {
        primary: 'rgba(1, 0, 0, 0.54)',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)'
      },
      typography: {
        useNextVariants: true,
        h1:{
          color: 'rgba(0, 0, 0, 0.54)'
        },
        h2:{
          color: 'rgba(0, 0, 0, 0.54)'
        },
        h3:{
          color: 'rgba(0, 0, 0, 0.54)'
        },
        h4:{
          color: 'rgba(0, 0, 0, 0.54)'
        },
        h6:{
          color: 'rgba(0, 0, 0, 0.54)'
        },
        caption:{
          color: 'rgba(0, 0, 0, 0.54)'
        }
      },
  palette: {
    primary: {
      main: blueGrey[400],
    },
    secondary: {
      main: '#FFE0B2',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fdfdfd',
    },
  },
});

export default theme;