import React, { useState,useEffect  }  from 'react';


import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {createGeoPoint } from '../fire';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    text: {
        padding: theme.spacing(0),
       
        
      },
      paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        
      },

    
  }));
function stripXML(url){
    if(url) return url.replace("varsel.xml","");
}
function addXML(url){
    if(url) return url + "varsel.xml";
}
function createGoogleURL(location){
    return "https://www.google.com/maps/@" + location.latitude +"," + location.longitude + ",14z";
}

function Yr (props){
    const classes = useStyles();
    const {oppdater, data} = props;
    const [googleURL, setGoogleURL] = useState("#");

    useEffect(() => {
        
        if(data && data.location){
            setGoogleURL(createGoogleURL(data.location));
        }else{

        }        
      }, [data]);

    function oppdaterYR(e){
        console.log(e.target.value);
        var myData = data?data:{};
        myData.url =addXML(e.target.value) 
        oppdater(myData);
    }

    function oppdaterLat(e){
        console.log(e.target.value);
        var myData = data?data:{};
        if(e.target.value==="")return;

        var geoVal = parseFloat(e.target.value);
        geoVal= Math.round(geoVal * 10000) / 10000;
        if(geoVal < -90 || geoVal > 90) return;

        console.log(geoVal);
        if(!myData.location){
            myData.location = createGeoPoint(geoVal,0)
        }else{
            myData.location =  createGeoPoint(geoVal,myData.location.longitude)
        }
        oppdater(myData);
        setGoogleURL(createGoogleURL(myData.location));
    }
    function oppdaterLon(e){
        console.log(e.target.value);
        var myData = data?data:{};
        if(e.target.value==="")return;
        var geoVal = parseFloat(e.target.value===""?0:e.target.value);
        geoVal= Math.round(geoVal * 10000) / 10000;
        if(geoVal < -180 || geoVal > 180) return;
        console.log(geoVal);
        if(!myData.location){
            myData.location = createGeoPoint(0,geoVal)
        }else{
            myData.location =  createGeoPoint(myData.location.latitude,geoVal)
        }
        setGoogleURL(createGoogleURL(myData.location));
         
        oppdater(myData);
    }


return(
    <Grid item xs={12}  >
        <Paper className={classes.paper}>
            <Typography variant="h6"  color="inherit" >
            YR 
            </Typography>
            <Grid container direction="row" >
                <Grid item xs={12}  >
                    <Typography variant="subtitle1"  color="inherit" >
                    Legg inn lokasjon. Bruk for eksempel google maps for å finne dine lokasjonsdata. 
                    </Typography>   
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                            className={classes.text}
                            id="lat" 
                            label="Latitude" 
                        
                            defaultValue={data.location?data.location.latitude:""}
                            helperText="-90/90"
                            onChange={oppdaterLat}
                            margin=
                            "dense"
                        />

                
                    <TextField 
                            className={classes.text}
                            id="lon" 
                            label="Longitude" 
                        
                            defaultValue={data.location?data.location.longitude:""}
                            helperText="-180/180"
                            onChange={oppdaterLon}
                            margin=
                            "dense"
                        />
                </Grid>
                <Grid item xs={12}>
                    <Link href={googleURL} target="_blank" rel="noopener">Sjekk lokasjonen på google maps {googleURL}</Link>   
                </Grid>
            </Grid>
        </Paper>
    </Grid>

)
}

export default Yr;