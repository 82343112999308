import React  from 'react';


import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({

    text: {
        padding: theme.spacing(0),
       
        
      },
      paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        
        
      },



    
  }));


function Bilde (props){
    const classes = useStyles();
    const {skjermID,loading} = props;
    var imgurl = "https://infoskjermen.appspot.com/clear/"+ skjermID+ ".png?" +loading;

    

return(
    
        <Paper className={classes.paper}>
            <Typography variant="h6"  color="inherit" >
                Skjermen 
            </Typography>
            <img src={imgurl}  height="500"/>
        </Paper>


)
}

export default Bilde;