import React ,{useState} from 'react';
import { AuthProvider, AuthService } from 'react-oauth2-pkce'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {hentNetatmo} from "../../fire";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({

    text: {
        padding: theme.spacing(0),
       
        
      },


    
  }));

  function MyButton (props){
      return(<Button type="submit" variant="contained">Hent stasjonsdata</Button>);
  }

  function LoginFeilet (props){
    return(<Alert severity="error">Logg inn feilet</Alert>);
}

function NetatmoLogin (props){
    const classes = useStyles();
    const {device} = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    
   
    function netatmoKall2(e){
        e.preventDefault();
       
        console.log(e.target.username.value);
    }

    function netatmoKall(e){
        e.preventDefault();
        setLoading(true);
        setError("");
        console.log("Henter Netatmo");
        hentNetatmo({brukernavn: e.target.username.value, passord: e.target.password.value}).then(result => {
            // Read result of the Cloud Function.
            
            
            device(result.data.devices,result.data.refresh_token);
            setLoading(false);
            // ...
          }).catch(err =>{
            setLoading(false)
            setError(<LoginFeilet />);
            console.log("error", err)});
          
    }


    


return(
    <Grid container direction="row" >
    <form name="hentDataFraNetatmo" className={classes.root} onSubmit={netatmoKall} >
    <Grid item xs={12}  >
        <Typography variant="subtitle1" className={classes.paper} color="inherit" >
            Logg inn med din Netatmobruker. For å få opp temperaturen på toppen av skjermen trenger vi å velge hvilken modul som skal brukes. 
        </Typography>   
    </Grid>
    <Grid item xs={12}  >

            <TextField 
                className={classes.text}
                id="username" 
                label="Brukernavn" 
                margin="dense"
                
            />  

            <TextField 
                className={classes.text}
                id="password" 
                label="passord"
                type="password"
                margin="dense"
            
            />  
            
            
            {loading
            ?<CircularProgress/>
            :<MyButton/>
            }
            {error}
       
    </Grid>
    </form>
</Grid>

)
}

export default NetatmoLogin;