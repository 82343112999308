import React  from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      
    },

  }));

function Personvern (){
    const classes = useStyles();

return(
    <Grid item xs={12}>
        <Typography variant="h2" className={classes.paper} color="inherit" >
          Personvern
        </Typography>  
        <Typography variant="body1" className={classes.paper} color="inherit" >
        Alt data blir lagret i USA i Google sin skyløsning. Jeg lagrer ingen informajson utover navn ,epostadresse og noen nøkler(refreshTokens) som brukes for å logge inn i netatmo og google. Dette trenger jeg for å kunne hente informasjon i skjermen. 
        Ønsker du å fjerne tilgangen som skjermen har fått kan dette gjøres ved å slette skjermen eller å fjerne tilgangen på google og netatmo sine sider.  
        </Typography>
        <Typography variant="h2" className={classes.paper} color="inherit" >
        Kaker
        </Typography>
        <Typography variant="body1" className={classes.paper} color="inherit" >
          Denne løsningen bruker ikke kaker utover det som må til for å beholde login mot Firebase. Det er nødvendig for at løsningen skal funke.   
        </Typography>    
    </Grid>

)
}

export default Personvern;