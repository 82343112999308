import React, { useContext } from 'react';
import { AuthContext } from ".././App";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {leggTilSkjermDB } from '.././fire';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',},
    },
    paper: {
      padding: theme.spacing(2),
      
      
    },
    text: {
        padding: theme.spacing(0),
       
        
      },
     

    button: {
      padding: theme.spacing(2),
      alignItems:"center",
      
      justifyContent: 'center'
    },
  }));


function LeggTilSkjerm(props) {
    const classes = useStyles();
    
    const { update, } = props;
    const Auth = useContext(AuthContext);

    function lagSkjerm(e){
      e.preventDefault();
      leggTilSkjermDB(document.nySkjerm.skjerm.value,document.nySkjerm.kallenavn.value,Auth.currentUser)
        .then(docRef => {
          console.log("Laget");
         })
        .catch(reason => console.log("Feilet i oppretting: " ,reason));
      console.log(props.userId);
      update();
    }

return (
    <Grid item xs={12}  >
        <Paper className={classes.paper}>
          <Grid container direction="row" >
            <form name="nySkjerm" className={classes.root} onSubmit={lagSkjerm} >
            <Grid item xs={12}>        
              <Typography variant="h5"  color="inherit" >
                Legg til skjerm 
              </Typography>   </Grid>
            <Grid item xs={12}>
            <TextField 
            className={classes.text}
                required 
                id="skjerm" 
                label="Skjermens ID" 
                margin="dense"
                />
            
            <TextField 
                className={classes.text}
                id="kallenavn" 
                label="kallenavn på skjermen" 
                margin="dense"
                /> 
                
                <Button type="submit">
                    Legg til
                </Button>
                
            </Grid>
            </form>
          </Grid>
        </Paper>
    </Grid>);
}

export default LeggTilSkjerm;