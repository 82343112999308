import React, { useContext } from 'react';
import { AuthContext } from "./App";
import Button from '@material-ui/core/Button';

import {fireAuth} from './fire';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      
    },
    button: {
      alignItems:"center",
      textAlign: "center",
      justifyContent: 'center'
    },
  }));


function LoggUt() {
    const classes = useStyles();
    const Auth = useContext(AuthContext);

    const handleGoogleSignOut = () => {
        fireAuth.signOut(); 
        //Auth.setLoggedIn(false);
     
    }


return (
    <Grid item xs={12}  className={classes.button}>
        <Button
        variant="contained"
        onClick={() => {handleGoogleSignOut(); }}           
        startIcon={ <img
                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                    alt="logo"
                    width="16px"
                    height="16px"/>
        }
        >
            Logg ut
        </Button>
    </Grid>);
}

export default LoggUt;