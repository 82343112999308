import React, {useState} from 'react';



import Grid from '@material-ui/core/Grid';
import {googleRefreshToken} from "../../fire";


import { makeStyles } from '@material-ui/core/styles';

import GoogleLogin from 'react-google-login';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({

    text: {
        padding: theme.spacing(0),
       
        
      },
      paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        
      },

    
  }));



function Offline (props){
    const classes = useStyles();

    const {oppdaterToken } = props;
    const [google, setGoogle] = useState();
    const [loading, setLoading] = useState(false);


   
    const responseGoogle = (response) => {
        setLoading(true);
        console.log(response);
        console.log(response.code);
        googleRefreshToken({code: response.code}).then(result => {
          // Read result of the Cloud Function.
          
          console.log(result);
          oppdaterToken(result.data);
          // ...
        }).catch(err =>{console.log("error", err)});

      }




return(
<Grid item xs={12}  >  
{loading?<LinearProgress color="secondary" />:
    <GoogleLogin
    clientId="380318689287-d47tpaqe6sfe7qobtud4qajm4jdsocdh.apps.googleusercontent.com"
    buttonText="La skjermen få tilgang til din kalender"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
    responseType='code' 
    prompt='consent' 
    accessType='offline'
  />}
</Grid>

)
}

export default Offline;