import React ,{useState} from 'react';
import { AuthProvider, AuthService } from 'react-oauth2-pkce'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {hentNetatmo} from "../../fire";
import Typography from '@material-ui/core/Typography';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({

    text: {
        padding: theme.spacing(0),
       
        
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },


    
  }));





function NetatmoData (props){
    const classes = useStyles();
    const {liste, data, oppdaterModule} = props;
    const [indoorV, setModules] = useState(data.indoor_id);
    const [outdoorV, setOutdoor] = useState(data.outdoor_id);
    

    const moduleListe = liste?liste
    .map((modul, i) => <MenuItem value={modul.indoor_id} key={modul.id} >{modul.navn}</MenuItem>):[]; 

    const handleChange = (event) => {
        setModules(event.target.value);
        oppdaterModule(event.target.value);
    };
    

return(
    <Grid container direction="row" >
     <Grid item xs={12}  >
        <Typography variant="subtitle1" className={classes.paper} color="inherit" >
            Velg modul du skal hente temperturen fra.  
        </Typography>   
    </Grid>
    <Grid item xs={12}  >

    <FormControl className={classes.formControl}>
        <InputLabel id="indoor-label">Velg modul</InputLabel>
        <Select
          labelId="indoor-label"
          id="indoor"
          value = {indoorV}
          onChange={handleChange}
        >
            {moduleListe}
        </Select>
        <FormHelperText>Velg modul</FormHelperText>
      </FormControl>
     
       
    </Grid>
    
</Grid>

)
}

export default NetatmoData;