import React, { useState, useEffect} from 'react';

import kemkakorrtheme from './Kemkakorrtheme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from '@material-ui/core/Grid';
import StartPage from './StartPage';
import LoggInn from './LoggInn';
import LoggUt from './LoggUt';
import Header from './Header';
import {getUserFromSession, fireAuth} from './fire';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import './App.css';
import Personvern from './Personvern';




export const AuthContext = React.createContext(null);



function App() {

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [currentUser, setUser] = useState(false);

  function readSession() {
    const userSession = getUserFromSession();
    
		if (userSession){ 
      setLoggedIn(true);
      setUser(userSession);
    }
  }
  useEffect(() => {
    //readSession();
    return fireAuth.onAuthStateChanged(user => {
      setUser(user);
      if(user){ 
        setLoggedIn(true);
        console.log("bruker er logget inn");
      }else{
        console.log("logget ut");
        setLoggedIn(false);
      }
      console.log("onAuthStateChanged");
    });

  }, [])
  


  return (
  <MuiThemeProvider theme={kemkakorrtheme}>
    <CssBaseline />
    <Router>
    <AuthContext.Provider value={{ currentUser, setUser }}>
        <Grid container direction="row" justify="center" alignItems="center" >
        <Header />
        <Switch>
          <Route path="/personvern">
            <Personvern />
          </Route>
          <Route path="/">
            {isLoggedIn
            ?<StartPage user={currentUser}/>
            :<LoggInn />
            }
            {isLoggedIn?<LoggUt />:""}
          </Route>
        </Switch>    
         
        <Link to="/personvern">Personvern og kaker</Link>
        </Grid>
     
    </AuthContext.Provider>
    </Router>
  </MuiThemeProvider>
  );
}

export default App;
