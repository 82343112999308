import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from ".././App";
import Button from '@material-ui/core/Button';

import {oppdaterSkjerm, slett } from '.././fire';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Yr from './Yr';
import Google from './Google/Google'; 
import Entur from './Entur';
import Netatmo from './Netatmo/Netatmo';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import Grid from '@material-ui/core/Grid';
import Bilde from './Bilde';


const useStyles = makeStyles((theme) => ({

    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2)
      
      
    },
    text: {
        padding: theme.spacing(0),
       
        
      },
     

    button: {
      padding: theme.spacing(2),
      alignItems:"center",
      
      justifyContent: 'center'
    },
  }));




function Skjerm(props) {

    const classes = useStyles();  
    const Auth = useContext(AuthContext);
    const { skjerm , update } = props;
    const [data, setData] = useState(skjerm.data);
    const [loading, setLoading] = useState(Date.now());
    



    
    function handleSubmit(e){
          e.preventDefault();
          console.log("handleSubmit");
          
          oppdaterSkjerm( skjerm.id,data)
            .then(docRef => {
              console.log("Laget");
             })
            .catch(reason => console.log("Feilet i oppretting: " ,reason));
          console.log(props.userId);
          setTimeout(() => {  setLoading(Date.now()); }, 2000);
          
          
        }
    function slettSkjerm(){
        slett(skjerm.id).then(docRef => {
          console.log("slettet");
          console.log(props);
          update();
         })
        .catch(reason => console.log("Feilet i sletting: " ,reason));
        
    }
    
    useEffect(() => {
        console.log("Data er oppdatert");
        console.log(data)

    },[data, setData]);

    function setFormData(formData){
        setData(formData);
        console.log(data);
    }
    function setGoogleData(google){
        console.log("google");
        console.log(google);
        data.google=google;
        setData(data);
    }

    function setYrData(yr){
        console.log(yr);
        data.yr=yr;
        setData(data);
    }
    function setNetatmoData(netatmo){
        console.log(netatmo);
        data.netatmo=netatmo;
        setData(data);
    }
  
return (

    <Grid item xs={12} className={classes.button}>
        <form name={skjerm.id} className={classes.root} onSubmit={handleSubmit} >
         <Grid container direction="row" justify="center" >
            <Grid item xs={12} >   
                     
                    <Typography variant="h4"  color="inherit" >
                    {skjerm.data.kallenavn} 
                </Typography>            
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container direction="row" >
                    

                    <Grid item xs={12}>
                    <Netatmo oppdater={setNetatmoData} data={data.netatmo} />  
                    <Google oppdater={setGoogleData} data={data.google} skjerm={skjerm.id} />     
                    <Yr oppdater={setYrData} data={data.yr} /> 
                    <Entur oppdater={setFormData} data={data} />

                        
                    </Grid>
                    
                </Grid>
          </Grid>
          <Grid item >
          <Bilde skjermID={skjerm.id} loading={loading} /> 
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit">
              Oppdater  {skjerm.data.kallenavn} 
            </Button>
            <IconButton aria-label="delete" onClick={() => { slettSkjerm();}}>
              <DeleteIcon />
            </IconButton>
          </Grid>
         </Grid>
         </form>
   </Grid>
    
    
    
    
    
    
    );
}

export default Skjerm;