import React  from 'react';


import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({

    text: {
        padding: theme.spacing(0),
       
        
      },
      paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        
      },



    
  }));


function Entur (props){
    const classes = useStyles();
    const {oppdater, data} = props;

    function oppdaterCalendar(e){
        console.log(e.target.value);
        
        oppdater(data);
    }

return(
    <Grid item xs={12}  >
        <Paper className={classes.paper}>
            <Typography variant="h6"  color="inherit" >
                Entur 
            </Typography>
            Her kommer det data om måten vi skal hente Entur. Dette  blir kanskje noe lenger
        </Paper>
    </Grid>

)
}

export default Entur;