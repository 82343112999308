import React, {useState, useContext} from 'react';
import {AuthContext} from '../../App'

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Offline from './Offline' 

const useStyles = makeStyles((theme) => ({

    text: {
        padding: theme.spacing(0),
       
        
      },
      paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        
      },

    
  }));


function Google (props){
    const classes = useStyles();
    const Auth =useContext(AuthContext);
    const {oppdater, data, skjerm} = props;
    const [google, setGoogle] = useState(data);


    function oppdaterCalendar(e){
        console.log(e.target.value);
        var myGoogle = google
        if(!myGoogle){
            myGoogle={};
        }
        
        myGoogle.calendar=e.target.value;
        setGoogle(myGoogle);
        console.log(myGoogle);
            oppdater(myGoogle);
        
    }
    function oppdaterToken(e){
        console.log("oppdaterToken",e);
        var myGoogle = google
        if(!myGoogle){
            myGoogle={};
        }
        
        myGoogle.refresh_token=e;
        setGoogle(myGoogle);
        console.log(myGoogle);
            oppdater(myGoogle);
        
    }
    
    function hasRefreshToken(){
        
        if(google && google.refresh_token){
            
            return true;
        }
        return false;
    }
var offline = !hasRefreshToken()?<Offline  oppdaterToken={oppdaterToken}/>:"Skjermen har tilgang til kalendern. Velg kalender";


return(
    <Grid item xs={12}  >
        <Paper className={classes.paper}>
        <Typography variant="h6"  color="inherit" >
        Google Kalender 
        </Typography>
        <Grid container direction="row" >
        <Grid item xs={12}  >
            <Typography variant="subtitle1"  color="inherit" >
                Velg hvilken kalender du ønsker å vise frem. Du finner ID i Google calender. Default kalenderid er som regel lik din egen epost adresse. Her finner du en oppskrift på <a href='https://docs.simplecalendar.io/find-google-calendar-id/' >denne siden</a>
            </Typography>   
        </Grid>
        <Grid item xs={12}  >
            <TextField 
                className={classes.text} id="kalender" 
                label="Kalender-ID" 
                fullWidth 
                defaultValue={data?data.calendar:Auth.currentUser.email}
                helperText=""
                onChange={oppdaterCalendar}
                margin="dense"
                />
        </Grid>
            
        {offline}
        
      </Grid>
        </Paper>
    </Grid>

)
}

export default Google;