import firebase from 'firebase/app'
import "firebase/auth";
import 'firebase/firestore';
import "firebase/functions";

var config = {
    apiKey: "AIzaSyApzN0UuoWk3aqslx-v9bjc9aRfn_sgBCA",
    authDomain: "infoskjermen.firebaseapp.com",
    databaseURL: "https://infoskjermen.firebaseio.com",
    projectId: "infoskjermen",
    storageBucket: "infoskjermen.appspot.com",
    messagingSenderId: "380318689287",
    appId: "1:380318689287:web:641a53327fd14f9bcba2a2"
  };
  var fire = firebase.initializeApp(config);


  if (window.location.hostname === 'localhost') {
    console.log("testing locally -- hitting local functions and firestore emulators");
    firebase.functions().useFunctionsEmulator('http://localhost:5000');
    
  }


  const firestore = firebase.firestore();
  const settingsCollection = "settings";

  export const fireAuth = firebase.auth();
  
  export const persistence = firebase.auth.Auth.Persistence.SESSION;
  export const getProvider =  () =>{
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/calendar.readonly");
    return provider;
  };

  export const createGeoPoint = (lat,lon) =>{
    return new firebase.firestore.GeoPoint(lat,lon);
  };
  export const  hentNetatmo = firebase.functions().httpsCallable('netatmo');
  export const  googleRefreshToken = firebase.functions().httpsCallable('googleRefreshToken');




  export const getSettings = userID => {
    return firestore.collection('settings')
        .doc(userID)
        .get();
  };

  export const getUserFromSession = () =>{

    return window.sessionStorage.getItem(
      `firebase:authUser:${config.apiKey}:[DEFAULT]`
    );
  }

  export const oppdaterSkjerm = (skjerm, data) =>{
    data.lastUpdated = firebase.firestore.FieldValue.serverTimestamp();
    console.log("oppdaterer", skjerm);
    return firestore.collection(settingsCollection).doc(skjerm)
        .set(data);

  }
  export const slett = (skjerm, ) =>{
   
    console.log("oppdaterer", skjerm);
    return firestore.collection(settingsCollection).doc(skjerm)
        .delete();

  }

  export const hentSkjermer = (userId) => {
    console.log(userId);
    return firestore.collection(settingsCollection).where("uid","==",userId).get();
    
  }


  export const leggTilSkjermDB = (skjerm, kallenavn,user) => {
    console.log("legger til", user.uid);
    return firestore.collection(settingsCollection).doc(skjerm)
        .set({
            uid: user.uid,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: user.displayName,
            kallenavn: kallenavn,
            yr:{url:"http://www.yr.no/sted/Norge/Akershus/Ullensaker/Jessheim/varsel.xml",
                location:new firebase.firestore.GeoPoint(60.1473,11.1585)}
            
            
            
        },{ merge: true });
};
    
  export default fire;