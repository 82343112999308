  import React, { useState, useEffect, useContext } from 'react';
import { hentSkjermer } from './fire';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import LeggTilSkjerm from './Skjerm/LeggTilSkjerm';
import Skjerm from './Skjerm/Skjerm';
import {AuthContext} from './App'

function StartPage(props) {
  const Auth =useContext(AuthContext);
  const [displayName, setDisplayName] = useState("");
  const [currentUser, setUser] =  useState(Auth.currentUser);
 
  const [ skjermer, setSkjermer ] = useState([]);
  const [ error, setError ] = useState();

  useEffect(() => {

    if(props.user) setDisplayName(props.user.displayName);


    
  }, [props.user])
function updateList()
{
  console.log("oppdaterer listen");
  var query = hentSkjermer(currentUser.uid)
  .then(snapshot => {
    const skjermListe = 
      snapshot.docs.map(docSnapshot => {
        return {
          data: docSnapshot.data(),
          id:docSnapshot.id
        }
         } );
                
      setSkjermer(skjermListe);
  
    })
  .catch(err => {
      setError('feil når jeg henter liste');
      console.log(err);
  });
}

useEffect(() => {
  
updateList();
    
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const skjermerListe = skjermer
.map((skjerm, i) => <Skjerm update={updateList} skjerm={skjerm}   />); 

    return (

      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center" >
        <Grid item xs={12}> 
          <Typography variant="h4" justify="center"  color="inherit" >
          Dine Skjermer {displayName}. 
          </Typography>    
        </Grid>
        {skjermerListe}
        <LeggTilSkjerm update={updateList} userId={currentUser.uid} />
         

        </Grid>
      </Grid>
      
  
    );
  }





  export default StartPage;