import React  from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      
    },

  }));

function Header (){
    const classes = useStyles();

return(
    <Grid item xs={12}>
        <Typography variant="h1" className={classes.paper} color="inherit" >
        kem ka korr 
        </Typography>    
    </Grid>

)
}

export default Header;